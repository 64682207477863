@import "../../scss/variables";
.mm_en{
  transform: rotate(-90deg);
  background-color: $primaryColor;
  position: relative;
   width: 370px;
   height: 42px;
  left: -164px;
   top: 164px;
   font-size: 16px;
   color: white;
   z-index: 1;
   text-align: center;
   margin-bottom: 10px;
   padding-top: 8px;
   text-transform: uppercase;
   border-top-right-radius: 10px;
   border-top-left-radius: 10px;
   visibility: visible;
   scroll-behavior: unset !important;

}

.mm_ar{
  transform: rotate(-90deg);
  font-size: 17px;
  background-color: $primaryColor;
  position: relative;
   width: 370px;
   height: 42px;
  right:  -164px;
   top: 164px;
   color: white;
   z-index: 1;
   text-align: center;
   margin-bottom: 10px;
   padding-top: 8px;
   text-transform: uppercase;
   border-bottom-right-radius: 10px;
   border-bottom-left-radius: 10px;
   visibility: visible;
   scroll-behavior: unset!important;

}
.sideStartEn {
  scroll-behavior: unset !important;
  overflow: hidden;
  border-radius: 12px;
  height: 370px;
  width: 280px!important;
  position: fixed;
  z-index: 200000;
  top: 150px;
  right:  0;
  background: white;
  overflow-x: hidden;
  padding-top: 0px;
  transition: 0s;
  box-shadow: 0px 2px 11px 0px rgba(147, 147, 147, 0.2);
 .MuiListItem-gutters {
  padding-left: 45px!important;
  // padding-right: 40px!important;
 }


  a {
    color: #fff;
    // &:hover {
    //   color: #fff;
    // }
  }
  .main-Title{
    font-size: 15px;
    font-weight: bold;
    padding-left: 50px;
  }
  .MuiList-root {
    margin-top: .5em;
    // .MuiSvgIcon-root {
    //   color: white;
    // }
  }

  .MuiListItemIcon-root {
    min-width: 25px !important;
    color: $primaryColor;
  }
  .MuiTypography-body1 {
    // color: $primaryColor !important;
    font-size: 13px !important;
  }

  .listItemStart {
    width: 98%;
    margin: 0 auto;
    &:hover,
    &.Mui-selected,
    &.Mui-selected:hover {
   text-decoration: underline $primaryColor;
   
      // .MuiTypography-body1 {
      //   color: white !important;
      // }
    }
  }
  .closebtnStart {
    transform: rotate(-90deg);
    color: $secondaryColor !important;
    background-color: transparent!important;
    font-size: 15px;
    margin-top: -30px;
    margin-left: 20px;
    .MuiSvgIcon-fontSizeSmall {
      font-size: 1.8rem;
  }
  }
  .closebtnStart:hover {
    transform: rotate(-90deg);
    color: $secondaryColor;
    font-size: 15px;
    margin-top: -30px;
    margin-left: 20px;
    .MuiSvgIcon-fontSizeSmall {
      font-size: 1.8rem;
  }
  }

  .openBtnStart {
    transform: rotate(-90deg);
    font-size: 15px;
    margin-top: -30px;
    margin-left: 20px;
    color: $secondaryColor !important;
    background-color: transparent!important;
    .MuiSvgIcon-root {
      font-size: 1.8rem;
      margin-left: 0.2em;
      color: $secondaryColor;
      z-index: 20000000;
    }
  }
  .curvedNavStart {
    position: fixed;
    right:  0;
     left:  auto;
    z-index: 10;
    top: 7em;
    transition: 0.5s;
    display: inline-block; 
    .openBtnStart {
      transform: rotate(90deg);
     visibility: visible;
      left:        1270px;
      position: absolute;
      top: 24em;
      z-index: 999;
      height: 38px;
      width: 20px;
      padding: 0;
      text-align: center;
      transition: 0.9s;
      border-radius: 90px 0 0 90px;
      .MuiSvgIcon-root {
        margin-left: 0.2em;
        color: $secondaryColor;
        z-index: 20000000;
      }
    }
    .closebtnStart {
       left:      1077px;
      position: absolute;
      transform: rotate(-90deg);
      top: 24em;
      z-index: 999;
      height: 38px;
      width: 20px;
      padding: 0;
      text-align: center;
      transition: 0.5s;
      border-radius: 90px 0 0 90px;
      .MuiSvgIcon-root {
        margin-left: 0em;
        color: $secondaryColor;
         z-index: 20000000;
      }
    }
  }

  .sideMenuItemListStart {
    min-height: 10vh;
    max-height: 60vh;
    // overflow: auto;
  }
}
.sideStartAr {
  overflow: hidden;
  border-radius: 12px;
  height: 370px;
  width: 280px!important;
  position: fixed;
  z-index: 200000;
  top: 150px;
  z-index: 1;
  top: 150px;
  left:   0;
  background: white;
  overflow-x: hidden;
  padding-top: 0px;
  transition: 0s;
  box-shadow: 0px 2px 11px 0px rgba(147, 147, 147, 0.2);
  .MuiListItem-gutters{
padding-right: 45px;
    padding-left: 0px;

  }
  a {
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
  .main-Title{
    font-size: 15px;
    font-weight: bold;
    padding-right: 50px;
  }
  .MuiList-root {
    margin-top: 1em;
    // .MuiSvgIcon-root {
    //   color: white;
    // }
  }

  .MuiListItemIcon-root {
    min-width: 25px !important;
    color: $primaryColor;
  }
  .MuiTypography-body1 {
    // color: $primaryColor !important;
    font-size: 13px !important;
  }

  .listItemStart {
    width: 98.5%;
    margin: 0 auto;
    &:hover,
    &.Mui-selected,
    &.Mui-selected:hover {
      text-decoration: underline $primaryColor;
      // .MuiTypography-body1 {
      //   color: white !important;
      // }
    }
  }
  .closebtnStart {
    // position: absolute;
    // top: 299px;
    // left: 11px;
    color: $secondaryColor !important;
    background-color: transparent!important;
    transform: rotate(90deg);
    color: $secondaryColor;
    font-size: 15px;
    margin-top: -25px;
    margin-left: 20px;
    .MuiSvgIcon-fontSizeSmall {
      font-size: 1.8rem;
      position: absolute;
      top: 290px;
      left: 10px;
  }
  }
  .closebtnStart:hover {
    transform: rotate(90deg);
    color: $secondaryColor;
    font-size: 15px;
    margin-top: -25px;
    margin-left: 20px;
    .MuiSvgIcon-fontSizeSmall {
      font-size: 1.8rem;
  }
  }

  .openBtnStart {
    transform: rotate(90deg);
    color: $secondaryColor;
    font-size: 15px;
    margin-top: -30px;
    margin-left: 20px;
    color: $secondaryColor !important;
    background-color: transparent!important;
    .MuiSvgIcon-root {
      font-size: 1.8rem;
      margin-left: 0.2em;
      color: $secondaryColor;
      z-index: 20000000;
      position: absolute;
      top: 290px;
      left: 10px;
    }
  }
  .curvedNavStart {
    position: fixed;
     left: 16em;
    right: auto;
    z-index: 10;
    top: 7em;
    transition: 0s;
    display: inline-block;
    .openBtnStart {
      transform: rotate(90deg);
      visibility: visible;
     display: block;
      left:  -230px;
      position: absolute;
      top: 23em;
      z-index: 999;
      // background-color: $transparentColor;
      height: 38px;
      width: 20px;
      padding: 0;
      text-align: center;
      transition: 0.9s;
      border-radius: 90px 0 0 90px;
      .MuiSvgIcon-root {
        margin-left: 0.2em;
        color: $secondaryColor;
        z-index: 30000000;
      }
    }
    .closebtnStart {
      transform: rotate(90deg);
      right:     10px;
      position: absolute;
      top: 23em;
      z-index: 1000000000;
      // background-color: $transparentColor;
      height: 38px;
      width: 20px;
      padding: 0;
      text-align: center;
      transition: 0.5s;
      border-radius: 90px 0 0 90px;
      .MuiSvgIcon-root {
        margin-left: 0.3em;
        color: $secondaryColor; 
        z-index: 30000000;

      }
    }
  }

  .sideMenuItemListStart {
    min-height: 10vh;
    max-height: 60vh;
    // overflow: auto;
  }
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
}

.my_interviews_icon {
  height: 1.6em;
}


.wrappers {
  border: 2px solid $primaryColor;
  width: 75%;
  margin-left: 50px;
  margin-right: 50px;
  border-radius: 15px;
}





