body {
  font-family: Montserrat-Regular;
  color: $darkTextColor;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $primaryColor;
}

.color-title {
  font-weight: bold;
  color: $primaryColor;
}

.contract-tabs-container {
  position: relative;
  .nav-link.active {
    background-color: white;
    color: $primaryColor;
    font-weight: 500;
    &::before {
      content: "";
      left: 0;
      height: 20px;
      position: absolute;
      border-left: 2px solid $primaryColor;
    }
  }
}

.notFoundImage {
  height: 20em;
}

a {
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.text-dark {
  color: $darkTextColor;
}
.txt-white {
  color: #fff;
}

.primary_color {
  color: $primaryColor;
}
.secondary_color {
  color: $secondaryColor;
}

.text-lignt {
  color: $lightTextColor;
}

legend {
  width: auto !important;
}

.MuiDrawer-paper {
  .MuiListItemIcon-root {
    color: $primaryColor !important;
  }
}

.MuiExpansionPanelSummary-content {
  margin: 0px !important;
}
.MuiExpansionPanelDetails-root {
  padding: 8px 24px !important;
}
button.btn-primary {
  font-family: Montserrat-Bold;
  background-color: $secondaryColor !important;
  border: 0;
  border-color: 0 !important;
  border-radius: 28px;
}

.borderRaduis {
  outline: none !important;
  border-radius: 28px !important;
}

.MuiTypography-body1 {
  font-family: inherit !important;
}

.MuiExpansionPanelDetails-root {
  flex-wrap: wrap;
}

.card_style {
  border-radius: 8px;
  border: 0;
  box-shadow: 0px 2px 11px 0px rgba(194, 194, 194, 0.7);
}

.bold_font {
  font-family: Montserrat-Bold;
}

.medium_font {
  font-family: Montserrat-Medium;
}

.regular_font {
  font-family: Montserrat-Regular;
}

.semi_bold_font {
  font-family: Montserrat-SemiBold;
}

.MuiTableCell-body {
  font-family: Montserrat-Regular;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 2px solid $primaryColor !important;
}

.width-75 {
  width: 75%;
}

.MuiButton-outlinedPrimary {
  border: 2px solid $primaryColor !important;
  &:hover {
    border: 2px solid $primaryColor !important;
  }
}

.MuiButton-outlinedSecondary {
  border: 2px solid $secondaryColor !important;
  &:hover {
    border: 2px solid $secondaryColor !important;
  }
}

.MuiButton-root {
  font-weight: 600 !important;
  text-transform: capitalize !important;
  font-family: Montserrat-Medium !important;
}

#alert-dialog-description {
  font-family: Montserrat-Regular;
}

.arabic_font {
  font-family: NeoSansArabic;
}

button:focus {
  outline: 0 !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
  color: $secondaryColor !important;
}
.MuiStepIcon-root.MuiStepIcon-active {
  color: $secondaryColor !important;
}
.MuiFormLabel-root.MuiInputLabel-outlined {
  background-color: white;
  padding-left: 5px;
  padding-right: 5px;
}

.fill_all {
  color: #f44336;
}
div[role="tooltip"] {
  z-index: 999999;
}

.light_bg_color {
  background-color: lightgray !important;
}

.companyLogoSideMenu {
  height: 100px;
  width: 100px;
  border: 1px solid $primaryColor;
  border-radius: 50%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.err-msg {
  color: $error;
  font-size: 0.75rem;
  margin-top: 3px;
  margin-inline-start: 12px;
  font-weight: 400;
  line-height: 1.66;
}
.phone-err-msg {
  color: $error;
  margin: 0;
  font-size: 0.75rem;
  margin-top: -7px;
  text-align: start;
  margin-inline-start: 14px;
  font-weight: 400;
  font-family: Montserrat-Regular, NeoSansArabic, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.action_icons_size {
  height: 2.5rem;
}
.agreeModalBtn {
  border-radius: 20px;
  box-shadow: 0px 2px 11px 0px rgba(85, 40, 48, 0.34);
  background: $secondaryColor;
  color: white;
}
.word-break {
  word-break: break-all;
}
.modal-label {
  .MuiFormLabel-root.MuiInputLabel-outlined {
    background-color: $modalPaperColor !important;
  }
}

.MuiDialog-paper {
  background-color: rgba(242, 242, 242, 1) !important;
  border-radius: 20px !important;
}

.pointer {
  cursor: pointer;
}

a[disabled],
button[disabled] {
  color: #dbdbdb;
  pointer-events: none;
  p {
    color: #dbdbdb;
  }
}

.disabled_row {
  background-color: #f7f7f7;
}

.requistionDescription .MuiFormHelperText-root {
  text-align: right;
}

.maxTextWidth {
  width: 200px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.maxContentWidth {
  width: max-content;
}

#offer_template_container .body {
  text-align: inherit;
  background-color: transparent;
}
.white-space-perline {
  white-space: pre-line;
}
.m-inline-end-0 {
  margin-inline-end: 0;
}
.fill-job-status {
  background-color: $transparentColor;
  border-radius: 11px;
  color: $secondaryColor;
  width: auto;
  padding: 4px 8px;
  font-size: 13px;
}
.color_gold {
  color: gold;
}
.text-danger-red {
  color: #f44336;
}
.relative_element {
  position: relative;
}

/* Shared classes Between components */
.custom-enabled-btn {
  column-gap: 10px;
}

@media only screen and (max-width: 400px) {
  .width-75 {
    width: 100%;
  }
}
