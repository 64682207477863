@import '../../../scss/variables';

.MuiButton-outlinedPrimary {
  border: 2px solid $primaryColor !important;
  &:hover {
    border: 2px solid $primaryColor !important;
  }
}

.MuiButton-outlinedSecondary {
  border: 2px solid $secondaryColor !important;
  &:hover {
    border: 2px solid $secondaryColor !important;
  }
}

.MuiButton-root {
    font-weight: 600 !important;
    text-transform: capitalize !important;
    font-family: Montserrat-Medium !important;
    outline: none !important;
    border-radius: 28px !important;
  }
  

.rtl {
  .MuiButton-root {
    font-family: NeoSansArabic !important;
  }
}
