.MuiDialog-paper {
    background-color: rgba(242, 242, 242, 1);
    border-radius: 20px;
    overflow: hidden;
    .dialogImage{
        height: 10em;
    }
}

.MuiDialog-root[dir='rtl']{
    .MuiDialogTitle-root{
        h2.MuiTypography-h6{
            .bold_font{
                font-family: NeoSansArabicBold;
            }
        }
    }
    .regular_font{
        font-family: NeoSansArabic;
        font-size: 14px;
    }
    .bold_font{
        font-family: NeoSansArabicBold;
        font-size: 14px;
    }
    .medium_font{
        font-family: NeoSansArabicMedium;
        font-size: 14px;
    }

    .MuiButton-label {
        font-family: NeoSansArabic;
    }
}

@media only screen and (max-width: 450px) {
    .MuiDialog-paper .dialogImage{
        height: 7em !important;
    }
}