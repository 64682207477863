@import "../../scss/variables";
.navbar-wrapper{
  margin-bottom: 100px;
}
.navbar {
  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.11);
  background-color: $primaryColor;
  margin-left: 250px;
  margin-right: 0;
  transition: all 0.5s ease 0s;
  .nav-container-width.container {
    max-width: 96% !important;
  }
  .navItem {
    text-decoration: none;
    margin-right: 1.5em;
    margin-left: 1.5em;
    align-self: center;
    .navbar-nav {
      color: $darkTextColor;
      text-decoration: none !important;
    }
    &:hover {
      color: $darkTextColor;
    }
  }

  .jobPortal,
  .langBtn {
    color: white !important;
    text-decoration: none;
    font-family: "NeoSansArabicBold" !important;
  }
  .jobPortal-link {
   width: auto
  }
  
  .MuiButtonBase-root.MuiButton-root.MuiButton-contained.jobPortal {
    line-height: 1;
    padding: 12px 18px 10px 18px !important;
  }
  .jobPortal:disabled {
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    background-color: #e0e0e0;

  }
  .border_right {
    background-color: lightgray;
    height: 1.5em;
    width: 0.1em;
    display: flex;
    align-items: center;
    align-self: center;
  }
  .navbar-toggler {
    border: 0 !important;
    outline: none;
  }
  // }

  .dropdown {
    button {
      cursor: pointer;

      &.btn-primary {
        background-color: transparent !important;
        color: $darkTextColor !important;
        &:hover {
          color: $darkTextColor !important;
        }
        &:focus,
        &:active {
          background-color: transparent !important;
          color: $darkTextColor !important;
          outline: none;
          box-shadow: none !important;
        }
      }
    }
  }

  .dropdown-menu {
    top: 200%;
    transition: 0.3s all ease-in-out;
    width: 50%;
    margin: 0 auto;
    margin-bottom: 1em;
  }
  .dropdown:hover > .dropdown-menu {
    // display: block;
    top: 100%;
    border: 0;
    box-shadow: 0px 2px 34px 0px rgba(127, 50, 63, 0.07);
  }

  .dropdown > .dropdown-toggle:active {
    outline: none;
  }

  .dropdown > .dropdown-toggle:focus {
    outline: none;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    background-color: $primaryColor;
  }

  .dropdown-item:hover {
    background-color: $primaryColor;
    color: white;
  }
  .unseen-icon{
    position: relative;
    &::before{
      position: absolute;
      top:15%;
      right: 0;
      background-color: rgba(234, 134, 133, 1);
      border:1px solid #fff;
      width: 9px;
      height: 9px;
      border-radius: 50%;
      content:""

    }

  }
}
.navbar-light .navbar-nav .nav-link {
  color: $darkTextColor !important;
  cursor: pointer;
}

.navbar-light .navbar-toggler {
  background-color: white;
}

.navbar-light .navbar-brand {
  color: white !important;
}

@media only screen and (max-width: 800px) {
  .signNav {
    font-size: 12px !important;
  }
  .navItem {
    line-height: 2em;
  }
}

.rtl {
  .navbar {
    margin-right: 250px;
    margin-left: 0 !important;
  }
}
