@import "../../scss/variables";

.excel-uploader {
  .download-sample {
    cursor: pointer;
    border-bottom: 1px solid transparent;
    &:hover {
      border-color: $primaryColor;
    }
  }
  .file-uploader {
    .uploade-image {
      position: relative;

      &__btn {
        background: $secondaryColor;
        color: #fff;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
          0px 2px 2px 0px rgba(0, 0, 0, 0.14),
          0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        cursor: pointer;
        font-size: 0.875rem;
        min-width: 64px;
        box-sizing: border-box;
        line-height: 1.65;
        letter-spacing: 0.02857em;
        font-weight: 600 !important;
        &.disabled {
          background-color: $lightGrey;
          pointer-events: none;
          &:hover {
            background-color: $lightGrey;
          }
        }
        &:hover {
          color: white;
          background-color: $secondaryColor;
        }

        input {
          color: transparent;
          // visibility: hidden;
          opacity: 0;
          position: absolute;
          left: 0;
          width: 100%;
          cursor: pointer;
        }
        input[type="file" i] {
          visibility: hidden;
        }
      }
    }
  }
}
