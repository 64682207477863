@import '../../scss/variables';

.wrapper-custom {
    border: 1px solid $primaryColor;
    .toolbar-custom {
        border-bottom: 1px solid $primaryColor;
        .rdw-option-wrapper {
            border: 1px solid $primaryColor;
        }
    }
    .editor-custom {
        height: 75vh;
    }
}
.marginb-5 {
    margin-bottom: 3rem;
}