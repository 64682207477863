
$widthValues:(10, 20, 30, 40, 50, 60, 70, 80, 100);
// different width sizes 
@each $widthVal in $widthValues{
    .w-#{$widthVal} {
        width: #{$widthVal}% !important;
    }
}

// Margin & padding classes 
$spaces: (0, 10, 20, 30, 40, 60, 80, 100, 120, 140, 160, 180, 200);
$sides: (top, bottom, left, right);
$screens: (xs, sm, md, lg, xl);

@each $screen in $screens {
  @include media-breakpoint-up($screen) {
    @each $space in $spaces {
      @each $side in $sides {
        .m#{str-slice($side, 0, 1)}-#{$screen}-#{$space} {
          margin-#{$side}: #{$space}px !important;
        }

        .p#{str-slice($side, 0, 1)}-#{$screen}-#{$space} {
          padding-#{$side}: #{$space}px !important;
        }
      }
    }
  }
}