@import "../../scss/variables";

.subscriptionCard {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  box-shadow: 0px 2px 11px 0px rgba(147, 147, 147, 0.2);
  .days_count {
    background-color: $transparentColor;
    border-radius: 4px;
    width: 4.3rem;
  }
  .hours_count {
    border: 1px solid $lightBorder;
    border-radius: 4px;
  }
  .time_label {
    font-size: 0.7rem;
  }
  .time_count {
    font-size: 1.35rem;
  }
  .free_trial_sentence {
    font-size: 1.4rem;
  }
  .subscribe_btn {
    border-radius: 5px !important;
  }
}
.rtl {
  .free_trial_img {
    transform: scaleX(-1);
  }
}
