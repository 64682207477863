@import "../../../scss/variables";

.applicantModal {
  .MuiFormControl-root .MuiFormLabel-root.MuiInputLabel-outlined {
    background-color: transparent !important;
  }
  .react-tel-input .form-control + div:before {
    background-color: #f2f2f2;
  }
  .react-tel-input::before {
    width: auto !important;
  }
 
}
.excited{
  border-top: 1px solid $secondaryColor;
  padding-top: 20px;
  margin-top: 20px;
}

.cone{
  width: 15rem;
  height: 15rem;
}
.react{
  width: 3rem;
  height: 3rem;
  margin: 20px 4px;
  
}
.cong{
  text-transform: uppercase;
}
.enjoy{
  width: 226px;
  height: 50px;
  font-size: 26px!important;
}