@import "../../scss/variables";
.file-uploader-wrapper {
  background: #f5f5f5;
  border: 2px dashed #dbdbdb;
  cursor: pointer;
  height: 120px;
  width: 195px;
  position: relative;
  border-radius: 14px;
}
.no-file-logo {
  color: #a5a5a5;
}
.file-uploader {
  .file-container {
    // width: 40vw;
    border: 2px dashed $lightGrey;
    padding: 2rem 1rem;
    p {
      word-break: break-word;
    }
  }
  .file-name {
    color: $darkTextColor;
  }
  .upload-logo-container {
    height: 54px;
    width: 54px;
    background-color: $secondaryColor;
    border-radius: 14px;
  }
  .file-type {
    color: #a5a5a5;
    font-size: 12px;
    text-transform: uppercase;
  }
  .upload-logo {
    height: 20px;
    width: 25px;
    border-radius: 14px;
  }
  .uploade-image {
    position: relative;

    &__img {
      width: 100px;
      height: 100px;
      border: 2px dashed lightgray;
      background-position: center;
      background-repeat: no-repeat;

      &--image {
        width: 50%;
       // height: 250px;
        margin: 0 auto;
      }
      &--lg {
        width: 150px;
        height: 150px;
        margin: 0 auto;
        border-radius: 50%;
      }

      &--triangle {
        width: 100%;
        height: 150px;
        border: 0;
      }
    }

    &__desc {
      max-width: 70%;
      color: black;
    }

    &__title {
      color: black;
    }

    &__btn {
      width: 100%;
      cursor: pointer;
      background: #f5f5f5;
      height: 100%;
      border-radius: 14px;
    }
  }
}
.file-input {
  opacity: 0;
  position: absolute;
  left: 0;
  width: 100%;
  cursor: pointer;
  height: 100%;
  z-index: 1;
}
.cancel-btn{
  position: absolute;
  top: 10px;
  z-index: 10;
}